.navbar {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: flex-end;
  /* border-bottom: 1px solid white; */
}
.navbar a,
.navbar label {
  user-select: none;
  margin: 15px;
  padding: 10px;
  color: white;
  cursor: pointer;
  border-radius: 25px;
  border: 2px solid transparent;
  transition: all 250ms ease;
}
.navbar a:hover {
  border: 2px solid white;
}
